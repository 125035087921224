(function (app) {
    'use strict';

    if (!app.request.is('producten-index')
        && !app.request.is('en-producten-index')
        && !app.request.is('fr-producten-index')
        && !app.request.is('de-producten-index')
    ) {
        return;
    }

    var labels = [
        'classic',
        'select',
        'gold'
    ];

    var $items = $('.item-product');
    var $filter = $('.filter');

    var filter = function (label) {
        if ($.inArray(label, labels) === -1) {
            label = null;
        }

        if (label) {
            // Show selection
            $items.parent().fadeOut();

            $items.filter(function () {
                return $(this).data('label') === label;
            }).parent().fadeIn();

            // Mark active
            $filter.find('.active').removeClass('active');
            $filter.find('a[href="#' + label + '"]').addClass('active');
        } else {
            // Show all
            $items.parent().fadeIn();

            // Mark active
            $filter.find('.active').removeClass('active');
            $filter.find('a[href="#"]').addClass('active');
        }
    };

    $(window).on('hashchange', function () {
        filter(location.hash.substr(1));
    });

    if (location.hash) {
        filter(location.hash.substr(1));
    }

}(App));
