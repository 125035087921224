(function (app) {
    'use strict';

    if (!app.request.is('producten-show')
        && !app.request.is('en-producten-show')
        && !app.request.is('fr-producten-show')
        && !app.request.is('de-producten-show')
    ) {
        return;
    }

    $('.product-content').each(function () {
        var height = 0;

        $(this).find('.product-image, .product-text').each(function () {
            var h = $(this).outerHeight();

            if (h > height) {
                height = h;
            }
        });

        $(this).outerHeight(height);
    });

}(App));
