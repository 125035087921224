(function (app) {
    'use strict';

    if (!app.request.is('special-home')
        && !app.request.is('special-home-locale')
        && !app.request.is('special-home-particulier')
        && !app.request.is('special-home-locale-particulier')
    ) {
        return;
    }

    var module = {};

    // Modal bedrijfsvideo zetten
    var $modalVideoSource = $('.item-video div');
    var $modalVideo = $('#modal-video');
    $modalVideo.find('iframe').attr('src', $modalVideoSource.data('src'));
    $modalVideo.find('#modal-video-title').html($modalVideoSource.data('title'));

    // Andere video's bij chefkok
    var $modalExtraVideos = $('#modal-media');
    $('.js-item-media').click(function () {
        var $item = $(this);
        var src = 'https://www.youtube.com/embed/' + $item.data('youtube') + '?rel=0&showinfo=0';

        $modalExtraVideos.find('iframe').attr('src', src);
        $modalExtraVideos.find('.modal-title').text($item.find('h3').text());

        $modalExtraVideos.modal();
    });


    // Video's laten stoppen met afspelen
    $modalVideo.on('hide.bs.modal', function () {
        $modalVideo.find('iframe').attr('src', '');
    });

    $modalExtraVideos.on('hide.bs.modal', function () {
        $modalExtraVideos.find('iframe').attr('src', '');
    });

    var $modalChefkok = $('#modal-chefkok');
    $modalChefkok.on('hide.bs.modal', function () {
        $modalChefkok.find('iframe').attr('src', '');
    });

    // Slider history
    (function () {
        var $wrap = $('.slider-history');
        var $historySlider = $wrap.find('.slides');

        $historySlider.slick({
            slide: '.item-timeline',
            infinite: false,
            slidesToShow: 7,
            slidesToScroll: 2,
            //initialSlide: 0,
            dots: false,
            prevArrow: $wrap.find('.slick-nav-prev'),
            nextArrow: $wrap.find('.slick-nav-next'),
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 375,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    })();

    var $wrap = $('.featured');
    // Slider featured
    (function () {

        app.ui.initSlick($wrap.find('.slider-main').data({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 7000,
            asNavFor: '.slider-nav .slides'
        }));

        app.ui.initSlick($wrap.find('.slider-nav').data({
            slidesToShow: 3,
            ignoreResponsive: true,
            slidesToScroll: 0,
            arrows: false,
            centerMode: true,
            centerPadding: '0px',
            asNavFor: '.slider-main .slides',
            focusOnSelect: true
        }));
    })();


    // Load instagram almost immediately
    setTimeout(function () {
        var $wrap = $('.instagram-wrap');

        $.ajax({
            method: 'POST',
            url: '/home/instagram',
            dataType: 'html',
            success: function (html) {
                $wrap.css('opacity', 0);

                $wrap.html(html);

                app.ui.initSlick($wrap.closest('.instagram').data({
                    infinite: false,
                    slidesToShow: 3,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 375,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                }));

                setTimeout(function () {
                    $wrap.animate({
                        opacity: 1
                    });

                    // object-fit fallback
                    if (!Modernizr.objectfit) {

                        $('.img-object-fit').each(function () {
                            var $this = $(this).find('img');
                            $this.parent().css({'background': 'transparent url("' + $this.attr('src') + '") no-repeat center center/cover'});
                            $this.remove();
                        });

                    }

                }, 300);
            },
            error: function () {
                $wrap.remove();
            }

        });

    }, 100);

    app.home = module;

}(App));
