(function (app) {
    'use strict';

    var module = {};

    module.menu = (function () {
        // Init menu

        var $nav = $('.menu-wrapper');

        $('.menu-trigger').click(function () {
            $(this).blur();

            module.menu.toggle();
        });

        $nav.find('a').click(function () {
            var $a = $(this);
            var href = $a.attr('href');

            if (location.pathname.indexOf('/producten') === 0 && href.indexOf('#') > 0) {
                location.href = href;
                location.reload(); // Trigger page load instead of only hash change
            }
        });

        return {
            open: function () {
                $nav.addClass('active').stop().slideDown();
            },
            close: function () {
                $nav.removeClass('active').stop().slideUp();
            },
            toggle: function () {
                if ($nav.hasClass('active')) {
                    module.menu.close();
                } else {
                    module.menu.open();
                }
            }
        };
    })();

    // disable right mouse menu
    $('body').contextmenu(function() {
        return false;
    });

    module.initSlick = function ($slider) {
        var slidesToShow = $slider.data('slidesToShow') || 1;
        var slidesToScroll = $slider.data('slidesToSroll') || $slider.data('slidesToShow') || 1;
        var responsive = $slider.data('ignoreResponsive') ? null : [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: slidesToShow - 1 <= 0 ? 1 : slidesToShow - 1,
                    slidesToScroll: slidesToScroll - 1 <= 0 ? 1 : slidesToScroll - 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: slidesToShow - 2 <= 0 ? 1 : slidesToShow - 2,
                    slidesToScroll: slidesToScroll - 2 <= 0 ? 1 : slidesToScroll - 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: slidesToShow - 3 <= 0 ? 1 : slidesToShow - 3,
                    slidesToScroll: slidesToScroll - 3 <= 0 ? 1 : slidesToScroll - 3
                }
            }
        ];

        var $slides = $slider.find('.slides');

        $slides.on('init', function () {
            $slides.animate({
                opacity: 1
            })
        });

        $slides.slick({
            slidesToShow: slidesToShow,
            slidesToScroll: slidesToScroll,
            prevArrow: $slider.find('.slick-nav-prev'),
            nextArrow: $slider.find('.slick-nav-next'),
            infinite: $slider.data('infinite') !== undefined ? $slider.data('infinite') : true,
            asNavFor: $slider.data('asNavFor') || null,
            centerMode: $slider.data('centerMode') || null,
            centerPadding: $slider.data('centerPadding') || '50px',
            focusOnSelect: $slider.data('focusOnSelect') || null,
            autoplay: $slider.data('autoplay') || false,
            autoplaySpeed: $slider.data('autoplaySpeed') || 3000,
            responsive: responsive
        });
    };

    // Popup
    (function () {
        var $popup = $('.js-overlay-popup');
        var key = 'ntm-popup-last-hidden-on';
        var today = moment().format('YYYY-MM-DD');
        var popupLastHiddenOn = app.cookie.get(key);
        var popupDate = $popup.data('date');

        // sws checken op dat datums in de toekomst uberhaupt niet getoond worden
        // Als item-datum nieuwer is de datum van de cookie, popup WEL (weer) tonen
        if (popupDate > today || popupLastHiddenOn >= popupDate) {
            var showPopup = false;
        } else {
            showPopup = true;
            app.cookie.set(key, '', 0);
        }

        if ($popup.length && showPopup) {
            var openOverlay = function () {
                setTimeout(function () {
                    $popup.fadeIn();
                }, 100);
            };
            var closeOverlay = function () {
                app.cookie.set(key, today);
                $popup.fadeOut();
            };

            if (document.readyState === 'complete') {
                openOverlay();
            } else {
                $(window).on('load', function () {
                    openOverlay();
                });
            }

            $popup.find('.js-btn-close-popup').click(function () {
                closeOverlay();
            });
        }
    })();

    // Particulier / professional switch
    (function () {
        var $switch = $('.switch-toggle');
        var key = 'professional';
        var professional = app.cookie.get(key);
        var saveAndGo = function (isProfessional) {
            app.cookie.set(key, isProfessional ? 'true' : 'false');

            var goTo = isProfessional ? location.pathname : 'https://www.nicetomeatyou.nl';

            if (location.pathname !== goTo) {
                $switch.css('pointerEvents', 'none');

                location.href = goTo;
                return false;
            } else {
                return true;
            }
        };

        if (professional === 'false') {
            $switch.find('input[value=particulier]').prop('checked', true);
        } else if (professional === 'true') {
            $switch.find('input[value=professional]').prop('checked', true);
        } else {
            var $overlay = $('.overlay-choose');

            if ($overlay.length) {
                var openOverlay = function () {
                    setTimeout(function () {
                        $overlay.slideDown();
                        $('body').addClass('modal-open');
                    }, 100);
                };
                var closeOverlay = function () {
                    $overlay.slideUp();
                    $('body').removeClass('modal-open');
                };

                if (document.readyState === 'complete') {
                    openOverlay();
                } else {
                    $(window).on('load', function () {
                        openOverlay();
                    });
                }

                $overlay.find('.item-overlay').click(function () {
                    var isProfessional = $(this).data('value') === 'professional';

                    if (saveAndGo(isProfessional)) {
                        $switch.find('input[value=professional]').prop('checked', true);

                        closeOverlay();
                    }
                });
            }
        }

        $switch.find('input').change(function () {
            var isProfessional = $(this).val() === 'professional';

            saveAndGo(isProfessional);

        });
    })();


    // [data-lineclamp]
    $('.row:has([data-lineclamp])').each(function () {
        var $row = $(this);
        var height = {};

        var isInteger = function (num) {
            return (num ^ 0) === num;
        };

        $row.find('[data-lineclamp]').each(function () {
            var tag = this.tagName;

            if (!isInteger(height[tag])) {
                height[tag] = 0;
            }

            var itemHeight = $(this).outerHeight();
            if (height[tag] < itemHeight) {
                height[tag] = itemHeight;
            }
        });

        for (var tag in height) {
            if (!height.hasOwnProperty(tag)) {
                continue;
            }

            $row.find(tag + '[data-lineclamp]').outerHeight(height[tag]);
        }
    });

    // Newsletter
    $('.form-newsletter').submit(function (e) {
        e.preventDefault();

        var $form = $(this);
        var $email = $form.find('input[name=email]');
        var email = $.trim($email.val());

        // Remove prev alerts
        $form.find('.alert').slideUp(function () {
            $(this).remove();
        });

        if (!email) {
            $email.closest('.form-group').addClass('has-error');
            return false;
        }

        $.ajax({
            method: 'POST',
            url: '/newsletter',
            data: $form.serialize(),
            dataType: 'json',
            success: function (msg) {
                $form.replaceWith($('<div class="alert alert-info"/>').text(msg));
            },
            error: function (jqXHR) {
                try {
                    var errors = jqXHR.responseJSON;

                    if (errors && errors['email']) {
                        $form.append($('<div class="alert alert-info"/>').text(errors['emails']));
                    } else {
                        throw new Error;
                    }
                } catch (e) {
                    alert('Er is een fout opgetreden, probeer later opnieuw.');
                }
            }
        })
    });

    $('.form-product').submit(function (e) {
        e.preventDefault();

        var $form = $(this);
        var $requiredInputs = $form.find(':input[required]');
        var hasError = false;

        // validation for empty
        $requiredInputs.each(function () {
            var $this = $(this);
            if ($this.val() === '') {
                $this.closest('.form-group').addClass('has-error');
                hasError = true;
            }
        });

        if (hasError) {
            return false;
        }

        $.ajax({
            method: 'POST',
            url: '/product/' + $form.find(':input[name="product"]').val(),
            data: $form.serialize(),
            dataType: 'json',
            success: function (msg) {
                $form.replaceWith($('<div class="alert alert-info"/>').text(msg));
            },
            error: function (jqXHR) {

                try {
                    var errors = jqXHR.responseJSON;

                    Object.keys(errors).forEach(function (name) {
                        var $input = $form.find(':input[name="' + name + '"]');

                        var $closestFormGroup = $input.closest('.form-group');

                        if (!$closestFormGroup.hasClass('has-error')) {
                            $closestFormGroup.addClass('has-error');
                            $closestFormGroup.find('.help-block').remove();
                            $input.after('<span class="help-block">' + errors[name] + '</span>');
                        }
                    });
                } catch (e) {
                    alert('An error occured, please try again.');
                }
            }
        });
    });

    // Contact form
    $('.form-contact').submit(function (e) {
        e.preventDefault();

        var $form = $(this);
        var $requiredInputs = $form.find(':input[required]');
        var hasError = false;

        // validation for empty
        $requiredInputs.each(function () {
            var $this = $(this);
            if ($this.val() === '') {
                $this.closest('.form-group').addClass('has-error');
                hasError = true;
            }
        });

        if (hasError) {
            return false;
        }

        $.ajax({
            method: 'POST',
            url: '/contact',
            data: $form.serialize(),
            dataType: 'json',
            success: function (msg) {
                $form.find(':input').val('').prop('checked', false);
                $form.append($('<div class="alert alert-info"/>').text(msg));
            },
            error: function (jqXHR) {

                try {
                    var errors = jqXHR.responseJSON;

                    Object.keys(errors).forEach(function (name) {
                        var $input = $form.find(':input[name="' + name + '"]');

                        var $closestFormGroup = $input.closest('.form-group');

                        if (!$closestFormGroup.hasClass('has-error')) {
                            $closestFormGroup.addClass('has-error');
                            $closestFormGroup.find('.help-block').remove();
                            $input.after('<span class="help-block">' + errors[name] + '</span>');
                        }
                    });
                } catch (e) {
                    alert('An error occured, please try again.');
                }
            }
        });
    });

    var $formJob = $('.form-job');
    var formHasAttachment = false;
    var dropzoneObj = null;
    var $dropzone = $formJob.find('.future-dropzone').addClass('dropzone');

    var html = $dropzone.html();
    $dropzone.empty();

    $dropzone.dropzone({
        url: '/job/' + $formJob.find(':input[name="vacature"]').val(),
        maxFiles: 1,
        maxFilesize: 10, // MB
        acceptedFiles: 'application/pdf',
        autoProcessQueue: false,
        addRemoveLinks: true,
        init: function () {
            dropzoneObj = this;
            dropzoneObj.on('addedfile', function (file) {
                if ($dropzone.hasClass('has-error')) {
                    $dropzone.removeClass('has-error');
                    $dropzone.find('.help-block').remove();
                }

                if (this.files.length > 1) {
                    this.removeFile(this.files[0]);
                }
                formHasAttachment = true;
            });
            dropzoneObj.on('removedfile', function (file) {
                formHasAttachment = false;
            });
        },
        dictCancelUpload: 'Annuleer uploaden',
        dictCancelUploadConfirmation: 'Geannuleerd',
        dictDefaultMessage: html,
        dictFallbackMessage: 'Je huidige browser ondersteunt deze uploadfunctie niet.',
        dictFallbackText: null,
        dictMaxFilesExceeded: 'Je mag maar één bestand tegelijk uploaden.',
        dictInvalidFileType: 'Dit bestandsformaat kan niet worden geüpload.',
        dictFileTooBig: 'Dit bestand is te groot.',
        dictResponseError: 'Er is een fout opgetreden ({{statusCode}}).',
        dictRemoveFile: '<i class="wb wb-close"></i>', // Als er een fout met het uploaden was, verandert het icoontje in deze string
        headers: {
            'X-CSRF-Token': $('meta[name="_token"]').attr('content')
        },
        sending: function (file, xhr, formData) {

            // vragen ook toevoegen
            $formJob.find(':input[name^="question"]:checked').each(function () {
                var $answer = $(this);
                var val = $answer.val();
                var questionId = $answer.closest('.js-question').data('id');

                formData.append('question[' + questionId + ']', val);
            });

            formData.append('message', $formJob.find(':input[name="message"]').val());
            formData.append('name', $formJob.find(':input[name="name"]').val());
            formData.append('email', $formJob.find(':input[name="email"]').val());
            formData.append('geslacht', $formJob.find(':input[name="geslacht"]:checked').val());
            formData.append('phone', $formJob.find(':input[name="phone"]').val());
        },
        success: function (file, html) {
            dropzoneObj.removeAllFiles(true);
            $formJob.replaceWith($('<div class="alert alert-info"/>').text('Uw sollicatie is succesvol verstuurd.'));
        },
        error: function (file, errorMsg, jqXHR) {
            dropzoneObj.removeAllFiles(true); // zie dictRemoveFile hierboven, dit voorkomt dat dat gedrag te zien is

            try {
                var errors = JSON.parse(jqXHR.responseText);

                Object.keys(errors).forEach(function (name) {
                    var $input = $formJob.find(':input[name="' + name + '"]');
                    var $closestFormGroup = $input.closest('.form-group');

                    if (!$closestFormGroup.hasClass('has-error')) {
                        $closestFormGroup.addClass('has-error');
                        $closestFormGroup.find('.help-block').remove();
                        $input.after('<span class="help-block">' + errors[name] + '</span>');
                    }
                });
            } catch (e) {
                alert('Er is iets misgegaan, uw sollicitatie is niet goed verstuurd. Probeer het nog eens.');
            }
        }
    });

    // Job application form
    $formJob.submit(function (e) {
        e.preventDefault();

        var $form = $(this);
        var hasError = false;
        var $requiredInputs = $form.find(':input[required]');

        // validation for empty
        $requiredInputs.each(function () {
            var $input = $(this);
            if ($input.val() === '') {
                $input.closest('.form-group').addClass('has-error');
                hasError = true;
            }
        });

        // Check for Dropzone attachment
        if (!formHasAttachment) {
            if (!$dropzone.hasClass('has-error')) {
                $dropzone.addClass('has-error');
                $dropzone.find('.help-block').remove();

                // dit was dus input binnen de formgroep
                $dropzone.append('<span class="has-error help-block">Voeg a.u.b. uw c.v. toe.</span>');
            }

            hasError = true;
        }

        if (hasError) {
            return false;
        }
        // Doorverwijzen naar Dropzone
        dropzoneObj.processQueue();
    });

    $('.img-parallax').each(function () {
        var img = $(this);
        var imgParent = $(this).parent();

        function parallaxImg() {
            var speed = img.data('speed');
            var imgY = imgParent.offset().top;
            var winY = $(window).scrollTop();
            var winH = $(window).height();
            var parentH = imgParent.innerHeight();

            // The next pixel to show on screen
            var winBottom = winY + winH;

            // If block is shown on screen

            //todo: stop met dingen doen when offscreen
            // if (winBottom > imgY && winY < imgY + parentH) {
            if (winBottom > imgY) {
                // Number of pixels shown after block appear
                var imgBottom = ((winBottom - imgY) * speed);
                // Max number of pixels until block disappear
                var imgTop = winH + parentH;
                // Porcentage between start showing until disappearing
                var imgPercent = ((imgBottom / imgTop) * 100) + (50 - (speed * 50));
            }
            img.css({
                top: imgPercent + '%',
                transform: 'translate(-50%, -' + imgPercent + '%)'
            });
        }

        $(document).on({
            scroll: function () {
                parallaxImg();
            }, ready: function () {
                parallaxImg();
            }
        });
    });


    app.ui = module;

}(App));
