(function (app) {
    'use strict';

    if (!app.request.is('in-de-media-index')
        && !app.request.is('en-in-the-media-index')
        && !app.request.is('fr-in-de-media-index')
        && !app.request.is('de-in-de-media-index')) {
        return;
    }

    var $modal = $('#modal-media');

    $modal.on('hide.bs.modal', function () {
        $modal.find('iframe').attr('src', '');
    });

    $('.item-media').click(function () {
        var $item = $(this);
        var src = 'https://www.youtube.com/embed/' + $item.data('youtube') + '?rel=0&showinfo=0';

        console.log($item.data('youtube'))

        $modal.find('iframe').attr('src', src);
        $modal.find('.modal-title').text($item.find('h3').text());

        $modal.modal();
    });

}(App));
